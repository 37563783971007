<template>
  <v-sheet
    rounded="xl"
    class="text-start px-6 py-8"
    maxWidth="786px"
    :width="$vuetify.breakpoint.smAndUp && $vuetify.breakpoint.mdAndDown ? '586px' : ''"
  >
    <stepper :completeStep="2" :currentStep="2" />
    <v-row class="px-3">
      <v-col cols="12 text-medium pb-0"> Confirm </v-col>
      <v-col cols="12">
        <table width="100%">
          <tbody>
            <tr>
              <td>
                <div class="text-h5 font-weight-medium">
                  {{ bnbInfo.balance | formatNumber(8) }} {{ bnbInfo.symbol }}
                </div>
                <div class="grey--text text--body-1">Your {{ bnbInfo.symbol }} balance</div>
              </td>
              <td>
                <div class="text-h5 font-weight-medium">{{ token.balance | formatNumber(10) }} {{ token.symbol }}</div>
                <div class="text--body-1">Your {{ token.symbol }} balance</div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="text-h5 font-weight-medium">{{ totalTokens | formatNumber(8) }} {{ token.symbol }}</div>
                <div class="grey--text text--body-1">Total number of token to be sent</div>
              </td>
              <td>
                <div class="text-h5 font-weight-medium">{{ editorData.length }}</div>
                <div class="grey--text text--body-1">Total number of address</div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="text-h5 font-weight-medium">{{ transNumber }}</div>
                <div class="grey--text text--body-1">Total number of transaction needed</div>
              </td>
              <td>
                <div
                  class="text-h5 font-weight-medium"
                  :class="isError || !isApproved ? 'line-error' : ''"
                  v-if="!estimateGasLoading"
                >
                  <span v-if="!isError && isApproved">
                    {{ estimateGas | formatNumber(8) }}
                  </span>
                  {{ isError ? 'Insufficient balance' : isApproved ? `BNB` : 'Waiting for approval' }}
                </div>
                <div v-else>
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    :size="20"
                    class="ml-10 d-flex align-center"
                  ></v-progress-circular>
                </div>
                <div class="grey--text text--body-1">Estimated Gas</div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col cols="12" v-if="isError">
        <v-sheet rounded="xl" class="secondary lighten-3 pa-6 frame-error">
          <div class="line-error text-body-1">Insufficient token balance</div>
        </v-sheet>
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between pb-0">
        <v-btn rounded class="primary" min-width="120px" @click="$router.go(-1)">Back</v-btn>
        <v-btn
          rounded
          class="primary"
          min-width="120px"
          v-if="isApproved"
          :loading="fetching"
          @click="$router.replace('step=3')"
          :disabled="isError"
          >Send</v-btn
        >
        <v-btn rounded class="primary" min-width="120px" v-else @click="onApprove()" :loading="approving || fetching"
          >Approve</v-btn
        >
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import fixedNumberHelper from '@/helpers/fixedNumberHelper.js'
import Stepper from '@/components/base/Stepper.vue'
export default {
  components: {
    Stepper,
  },
  data() {
    return {
      estimateGasLoading: false,
      claimId: '',
    }
  },
  computed: {
    ...mapState('claimAddWallet', ['estimateGas', 'transNumber', 'editorData', 'totalTokens']),
    ...mapState('claimAddWalletConfirm', ['approving', 'isApproved', 'fetching']),
    ...mapState('claimDetailByOwner', ['token']),
    ...mapState('auth', ['bnbInfo', 'multiClaimHandler']),
    isError: function () {
      return fixedNumberHelper.gt(this.totalTokens, this.token.balance)
    },
  },
  methods: {
    ...mapActions('claimAddWalletConfirm', ['onApproveContract', 'fetchData']),
    ...mapActions('claimAddWallet', ['handleEstimateGas']),
    async onApprove() {
      this.estimateGasLoading = true
      await this.onApproveContract([this.multiClaimHandler, this.token])
      if (this.isApproved) {
        const estimateGas = await this.handleEstimateGas({
          id: this.claimId,
          decimals: this.token ? this.token.decimals : 18,
        })
        this.$store.commit('claimAddWallet/changeState', { estimateGas })
      }
      this.estimateGasLoading = false
    },
  },
  async created() {
    await this.fetchData([this.multiClaimHandler, this.token])
  },
  mounted() {
    this.claimId = this.$route.params.id
  },
}
</script>
<style scoped>
td {
  padding-top: 18px;
  padding-left: 18px;
  padding-bottom: 18px;
}
table,
th,
td {
  border: 1px solid gray;
  border-collapse: collapse;
}
.line-error {
  color: var(--v-error-lighten1) !important;
}
</style>
